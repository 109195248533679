<template>
  <div v-if="label">
    {{showText}}
  </div>
</template>
<script>
export default {
  props: [
    'label',
    'min',
    'max',
  ],
  computed: {
    showText() {
      if (this.label?.indexOf('{') === -1 || this.label?.indexOf('}') === -1 || !this.min || !this.max) {
        return this.label
      }
      return this.errorTemplate(this.label, {
        min_value: this.min,
        max_value: this.max,
      })
    },
  },
  methods: {
    errorTemplate(template, vars = {}) {
      const handler = new Function('vars', [
        'const tagged = ( ' + Object.keys(vars).join(', ') + ' ) =>',
        '`' + template + '`',
        'return tagged(...Object.values(vars))'
      ].join('\n'))

      return handler(vars)
    },
  },
}
</script>
