<template>
  <div class="overlay">
    <page-title>{{ i18n.MENU.options }}</page-title>
    <div class="accordion">
      <div
        :class="[
          'accordion__item',
          { 'accordion__item--opened': showConstruction }
        ]"
      >
        <button class="accordion__heading" @click.prevent="toggleConstruction">
          <div class="accordion__text">{{ i18n.OPTIONS.CONSTRUCTION }}</div>
        </button>
        <div
          :class="[
            'accordion__content',
            { 'accordion__content--active': showConstruction }
          ]"
        >
          <div class="page-width">
            <div>
              <div class="options ">
                <div class="accordion__empty" v-if="construction.length === 0">
                  {{ i18n.OPTIONS.EMPTY_CONSTRUCTION }}
                </div>
                <div class="categories">
                  <div
                    class="categories__main"
                    v-for="(item, index) in construction"
                    :key="`options__${item.ord}`"
                  >
                    <label
                      :class="[
                        'options__item',
                        'categories__item',
                        {
                          'options__item--current': item.construction_thikness.some(
                            construction => construction.id === currentId
                          )
                        },
                        {
                          'options__item--active': item
                        }
                      ]"
                    >
                      <div class="categories__present">
                        <div>
                          <span class="options__bg">
                            <img class="options__img" :src="item.image_png" />
                          </span>
                          <div class="options__title">{{ item.title }}</div>
                        </div>
                      </div>
                      <div class="radio">
                        <div
                          class="option"
                          v-for="(size, index) in item.construction_thikness"
                          :key="`option_${size.id}`"
                        >
                          <p :class="`option__${index}`">
                            <input
                              type="radio"
                              :id="`size_${size.id}`"
                              :value="size.id"
                              :checked="size.id === currentId"
                              @change.prevent="setConstruction(size, options)"
                              name="size"
                            />
                            <label :for="`size_${size.id}`">{{
                              size.construction_thikness + ' мм'
                            }}</label>
                          </p>
                        </div>
                      </div>
                    </label>
                    <hr
                      :class="
                        `categories__hr hr__${
                          construction.length - 1 === index ? 'last' : index
                        }`
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="['accordion__item', { 'accordion__item--opened': showInoxes }]"
      >
        <button class="accordion__heading" @click.prevent="toggleInoxes">
          <div class="accordion__text">{{ i18n.OPTIONS.INOX }}</div>
        </button>
        <div
          :class="[
            'accordion__content',
            { 'accordion__content--active': showInoxes }
          ]"
        >
          <div class="page-width">
            <div>
              <div class="options">
                <div
                  class="accordion__empty"
                  v-if="filteredInoxes.length === 0"
                >
                  {{ i18n.OPTIONS.EMPTY_CONSTRUCTION }}
                </div>
                <label
                  :class="[
                    'options__item',
                    { 'options__item--current': item.id === inox.id },
                    { 'options__item--active': item.active === '1' }
                  ]"
                  v-for="item in filteredInoxes"
                  @click.prevent="setInox(item, item.id === inox.id)"
                  :key="`inox_${item.id}`"
                >
                  <span class="options__bg">
                    <img class="options__img" :src="item.image_png" />
                  </span>
                  <div class="options__title">{{ item.title }}</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="[
          'accordion__item',
          { 'accordion__item--opened': showDirection }
        ]"
      >
        <button class="accordion__heading" @click.prevent="toggleDirection">
          <div class="accordion__text">{{ i18n.OPTIONS.DIRECTION }}</div>
        </button>
        <div
          :class="[
            'accordion__content',
            { 'accordion__content--active': showDirection }
          ]"
        >
          <div class="page-width">
            <div>
              <div class="options">
                <div class="accordion__empty" v-if="views.length === 0">
                  {{ i18n.OPTIONS.EMPTY_DIRECTION }}
                </div>
                <label
                  :class="[
                    'options__item',
                    { 'options__item--current': item.id === currentViewId },
                    { 'options__item--active': item.active === '1' }
                  ]"
                  v-for="item in views"
                  @click.prevent="setView(item, item.id === view.id)"
                  :key="`view__${item.id}`"
                >
                  <span class="options__bg">
                    <img class="options__img" :src="item.image" />
                  </span>
                  <div class="options__title">{{ item.title }}</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="model.mirrored_code"
        :class="[
          'accordion__item',
          { 'accordion__item--opened': showMirrored }
        ]"
      >
        <button class="accordion__heading" @click.prevent="toggleMirrored">
          <div class="accordion__text">{{ i18n.OPTIONS.MODEL_VIEW }}</div>
        </button>
        <div
          :class="[
            'accordion__content',
            { 'accordion__content--active': showMirrored }
          ]"
        >
          <div class="page-width">
            <div
              class="option"
              :key="`mir_${item.id}`"
              v-for="item in mirroreds"
            >
              <p>
                <input
                  type="radio"
                  :id="`mir_${item.id}`"
                  :value="item.id"
                  :checked="item.id === mirrored.id"
                  @change="setMirrored(item, item.id === mirrored.id)"
                  name="mirrored"
                />
                <label :for="`mir_${item.id}`">{{ item.title }}</label>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="[
          'accordion__item',
          { 'accordion__item--opened': showSafeGlass }
        ]"
      >
        <button class="accordion__heading" @click.prevent="toggleSafeGlass">
          <div class="accordion__text">{{ i18n.OPTIONS.SAFE_GLASS }}</div>
        </button>
        <div
          :class="[
            'accordion__content',
            { 'accordion__content--active': showSafeGlass }
          ]"
        >
          <div class="page-width">
            <div class="option" :key="`mir_${item.id}`" v-for="item in safes">
              <p>
                <input
                  type="radio"
                  :id="`safeglass_${item.id}`"
                  :value="item.id"
                  :checked="item.id === safeglass.id"
                  @change="setSafeGlass(item, item.id === safeglass.id)"
                  name="safeglass"
                />
                <label :for="`safeglass_${item.id}`">{{ item.title }}</label>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="['accordion__item', { 'accordion__item--opened': showSizes }]"
      >
        <button class="accordion__heading" @click.prevent="toggleSizes">
          <div class="accordion__text">{{ i18n.OPTIONS.SIZES }}</div>
        </button>
        <div
          :class="[
            'accordion__content',
            { 'accordion__content--active': showSizes }
          ]"
        >
          <validation-observer v-slot="{ handleSubmit }">
            <form action="#" @submit.prevent="handleSubmit(optionsProcess)">
              <div class="page-width">
                <div class="size">
                  <div class="size__title">{{ i18n.OPTIONS.SIZES_SELECT }}</div>
                  <div class="size__text">
                    {{ i18n.OPTIONS.W }}
                    <validation-provider
                      :rules="`required|number_between:${minWidth},${maxWidth}`"
                      v-slot="{ errors }"
                    >
                      <input
                        type="number"
                        v-model="orderWidth"
                        step="1"
                        @input="
                          check({
                            e: $event,
                            max: maxWidth,
                            min: minWidth,
                            param: 'orderWidth'
                          })
                        "
                        @change="
                          $store.dispatch('config/CONFIG_SET_WIDTH', orderWidth)
                        "
                        class="size__field"
                      />
                      <label class="error">
                        <error-label
                          :min="minWidth"
                          :max="maxWidth"
                          :label="i18n['VALIDATE'][errors[0]]"
                        />
                      </label>
                    </validation-provider>
                    - {{ i18n.OPTIONS.H }}
                    <validation-provider
                      :rules="
                        `required|number_between:${minLength},${maxLength}`
                      "
                      v-slot="{ errors }"
                    >
                      <input
                        type="number"
                        v-model="orderLength"
                        step="1"
                        @input="
                          check({
                            e: $event,
                            max: maxLength,
                            min: minLength,
                            param: 'orderLength'
                          })
                        "
                        @change="
                          $store.dispatch(
                            'config/CONFIG_SET_HEIGHT',
                            orderLength
                          )
                        "
                        class="size__field"
                      />
                      <label class="error">
                        <error-label
                          :min="minLength"
                          :max="maxLength"
                          :label="i18n['VALIDATE'][errors[0]]"
                        />
                      </label>
                    </validation-provider>
                    {{ i18n.OPTIONS.MM }}
                  </div>
                </div>
                <div class="size">
                  <div
                    class="size__title"
                    v-if="shape.glass_left && shape.glass_right"
                  >
                    Бічні панелі
                  </div>
                  <div
                    class="size__title"
                    v-else-if="shape.glass_left || shape.glass_right"
                  >
                    Бічна панель
                  </div>
                  <div class="size__text">
                    <div v-if="shape.glass_left" class="size__group">
                      <span>{{ i18n.OPTIONS.L }}</span>
                      <input
                        type="number"
                        v-model="leftWidth"
                        min="300"
                        step="50"
                        max="450"
                        @change="
                          $store.dispatch(
                            'config/CONFIG_SET_LEFT_WIDTH',
                            leftWidth
                          )
                        "
                        class="size__field"
                      />
                    </div>
                    <div v-if="shape.glass_right" class="size__group">
                      <span>{{ i18n.OPTIONS.R }}</span>
                      <input
                        type="number"
                        v-model="rightWidth"
                        min="300"
                        step="50"
                        max="450"
                        @change="
                          $store.dispatch(
                            'config/CONFIG_SET_RIGHT_WIDTH',
                            rightWidth
                          )
                        "
                        class="size__field"
                      />
                      <span>{{ i18n.OPTIONS.MM }}</span>
                    </div>
                  </div>
                </div>
                <div class="size" v-if="shape.glass_top">
                  <div class="size__title">{{ i18n.OPTIONS.FRAME }}</div>
                  <div class="size__text">
                    <div class="size__lab">{{ i18n.OPTIONS.HEIGHT }}</div>
                    <input
                      type="number"
                      v-model="topHeight"
                      min="300"
                      step="50"
                      max="600"
                      @change="
                        $store.dispatch(
                          'config/CONFIG_SET_TOP_HEIGHT',
                          topHeight
                        )
                      "
                      class="size__field size__field--full"
                    />
                  </div>
                </div>
                <div class="additionaly" v-html="optionsDescription" />
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue';
import ErrorLabel from '@/components/shared/error-label';
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  validate
} from 'vee-validate';
import { required, min_value, max_value } from 'vee-validate/dist/rules';
import { API } from '@/constants/api';
import { setCode } from '@/utils/code';

extend('required', {
  ...required,
  message: 'REQUIRED'
});

extend('min_value', min_value);
extend('max_value', max_value);

extend('number_between', {
  async validate(value, { min_value, max_value }) {
    const res = await validate(
      value,
      `min_value:${parseInt(min_value, 10)}|max_value:${parseInt(
        max_value,
        10
      )}`
    );
    return res.valid;
  },
  params: ['min_value', 'max_value'],
  message: 'BETWEEN'
});

export default {
  data() {
    return {
      showConstruction: false,
      showDirection: false,
      showMirrored: false,
      showSafeGlass: false,
      showInoxes: false,
      showSizes: false,
      activeTab: 0,
      construction: [],
      inoxes: [],
      views: [],
      mirroreds: [],
      safes: [],
      orderWidth: 0,
      orderLength: 0
    };
  },
  computed: {
    minWidth() {
      return (parseInt(this.model.min_model_width, 10) || 1150) + 300;
    },
    maxWidth() {
      const isAluminium = this.color[this.house].is_alu !== '0';
      if (isAluminium) {
        return 1200 + 300;
      }
      return 896 + 300;
    },
    minLength() {
      return (parseInt(this.model.min_model_height, 10) || 2300) + 300;
    },
    maxLength() {
      const isAluminium = this.color[this.house].is_alu !== '0';
      if (isAluminium) {
        return 2300 + 300;
      }
      return 2146 + 300;
    },
    leftWidth: {
      get() {
        return this.dimensions?.leftWidth;
      },
      set(w) {
        this.$store.commit('config/CONFIG_SET_LEFT_WIDTH', parseInt(w, 10));
      }
    },
    rightWidth: {
      get() {
        return this.dimensions?.rightWidth;
      },
      set(w) {
        this.$store.commit('config/CONFIG_SET_RIGHT_WIDTH', parseInt(w, 10));
      }
    },
    topHeight: {
      get() {
        return this.dimensions?.topHeight;
      },
      set(h) {
        this.$store.commit('config/CONFIG_SET_TOP_HEIGHT', parseInt(h, 10));
      }
    },
    filteredInoxes() {
      return this.inoxes.filter(item => item.black_design === '');
    },
    hiddenInoxes() {
      return this.inoxes.filter(item => item.black_design !== '');
    },
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'inox',
      'view',
      'house',
      'mirrored',
      'safeglass',
      'dimensions',
      'black'
    ]),
    ...mapGetters('session', ['i18n']),
    currentViewId() {
      return this.view.id;
    },
    currentId() {
      return this.options.id;
    },
    optionsDescription() {
      return this.i18n.OPTIONS.DESCRIPTION;
    }
  },
  components: {
    PageTitle,
    ErrorLabel,
    ValidationProvider,
    ValidationObserver
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.options,
      meta: [
        { vmid: 'description', property: 'description', content: 'Опції' },
        { vmid: 'og:title', property: 'og:title', content: 'Опції' },
        { vmid: 'og:description', property: 'og:description', content: 'Опції' }
      ]
    };
  },
  methods: {
    optionsProcess() {},
    toggleConstruction() {
      if (this.showConstruction) {
        this.showConstruction = false;
      } else {
        this.showConstruction = true;
        this.showDirection = false;
        this.showMirrored = false;
        this.showSafeGlass = false;
        this.showInoxes = false;
        this.showSizes = false;
      }
    },
    toggleDirection() {
      if (this.showDirection) {
        this.showDirection = false;
      } else {
        this.showConstruction = false;
        this.showDirection = true;
        this.showMirrored = false;
        this.showSafeGlass = false;
        this.showInoxes = false;
        this.showSizes = false;
      }
    },
    toggleMirrored() {
      if (this.showMirrored) {
        this.showMirrored = false;
      } else {
        this.showConstruction = false;
        this.showDirection = false;
        this.showMirrored = true;
        this.showSafeGlass = false;
        this.showInoxes = false;
        this.showSizes = false;
      }
    },
    toggleSafeGlass() {
      if (this.showSafeGlass) {
        this.showSafeGlass = false;
      } else {
        this.showConstruction = false;
        this.showDirection = false;
        this.showMirrored = false;
        this.showSafeGlass = true;
        this.showInoxes = false;
        this.showSizes = false;
      }
    },
    toggleInoxes() {
      if (this.showInoxes) {
        this.showInoxes = false;
      } else {
        this.showConstruction = false;
        this.showDirection = false;
        this.showMirrored = false;
        this.showSafeGlass = false;
        this.showInoxes = true;
        this.showSizes = false;
      }
    },
    toggleSizes() {
      if (this.showSizes) {
        this.showSizes = false;
      } else {
        this.showConstruction = false;
        this.showDirection = false;
        this.showMirrored = false;
        this.showSafeGlass = false;
        this.showInoxes = false;
        this.showSizes = true;
      }
    },
    check({ e, param, min, max }) {
      this.submit({ e, param });

      setTimeout(() => {
        if (e.target.value > max) {
          e.target.value = max;
        }
        if (e.target.value < min) {
          e.target.value = min;
        }

        this.submit({ e, param });
      }, 5000);
    },
    submit({ e, param }) {
      this[param] = e.target.value;

      if (param === 'orderWidth') {
        this.$store.dispatch('config/CONFIG_SET_WIDTH', e.target.value);
      } else if (param === 'orderLength') {
        this.$store.dispatch('config/CONFIG_SET_HEIGHT', e.target.value);
      }
    },
    getConstruction() {
      axios
        .get(API.GET_OPTIONS, {
          params: {
            category: 'konstruktsiya'
          }
        })
        .then(response => (this.construction = response.data));
    },
    getInox() {
      axios
        .get(API.GET_INOX, {
          params: {
            active: 1,
            model: this.model.id,
            colour_outside: this.color.outside.id,
            colour_inside: this.color.inside.id
          }
        })
        .then(response => (this.inoxes = response.data));
    },
    async setInox(item, isActive) {
      if (isActive) {
        return false;
      }
      // TODO: Select black design option.
      // if (this.black === 1) {
      //   item = this.hiddenInoxes.filter(i => i.inox_type === item.inox_type)
      // }
      // item.forEach(e => console.log(e, e.title))
      let response;
      const {
        shape,
        model,
        glass,
        color,
        furniture,
        options,
        // inox,
        view,
        mirrored
      } = this;
      const moldingView = item?.url.includes('molding')
        ? item?.url.split('-')[1]
        : null;

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furniture.id,
            options: options.id,
            inox: item.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            black: 'none'
          }
        });
      } catch (e) {
        console.error(e);
      }
      await setCode({
        code: response.data.code,
        store: this.$store,
        router: this.$router
      });
      await this.$store.dispatch('config/CONFIG_SET_INOX', item);
      await this.$store.dispatch('config/CONFIG_SET_BLACK', 'none');
    },
    getView() {
      axios
        .get(API.GET_VIEW + '?model=' + this.model.id)
        .then(response => (this.views = response.data));
    },
    getDirection() {
      axios
        .get(API.GET_MIRRORED + '?model=' + this.model.id)
        .then(response => (this.mirroreds = response.data));
    },
    getSafe() {
      axios
        .get(API.GET_SAFE + '?glass_id=' + this.glass.id)
        .then(response => (this.safes = response.data));
    },
    async setView(item, isActive) {
      if (isActive) {
        return false;
      }
      let response;
      const {
        shape,
        model,
        glass,
        color,
        furniture,
        options,
        inox,
        mirrored,
        black
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;
      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furniture.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: item.id,
            mirrored: mirrored.id,
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      await setCode({
        code: response.data.code,
        store: this.$store,
        router: this.$router
      });
      await this.$store.dispatch('config/CONFIG_SET_VIEW', item);
    },
    async setMirrored(item, isActive) {
      if (isActive) {
        return false;
      }
      let response;
      const {
        shape,
        model,
        glass,
        color,
        furniture,
        options,
        inox,
        view,
        black
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furniture.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: item.id,
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      await setCode({
        code: response.data.code,
        store: this.$store,
        router: this.$router
      });
      await this.$store.dispatch('config/CONFIG_SET_MIRRORED', item);
    },
    async setSafeGlass(item, isActive) {
      if (isActive) {
        return false;
      }
      let response;
      const {
        shape,
        model,
        glass,
        color,
        furniture,
        options,
        inox,
        mirrored,
        view,
        black
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furniture.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            safeglass: item.id,
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      await setCode({
        code: response.data.code,
        store: this.$store,
        router: this.$router
      });
      await this.$store.dispatch('config/CONFIG_SET_SAFE_GLASS', item);
    },
    async setConstruction(item, options) {
      if (item.id === options.id || item.active === '0') {
        return false;
      }
      let response;
      const {
        shape,
        model,
        glass,
        color,
        furniture,
        // options,
        inox,
        view,
        black
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furniture.id,
            options: item.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      await setCode({
        code: response.data.code,
        store: this.$store,
        router: this.$router
      });
      await this.$store.dispatch('config/CONFIG_SET_CONSTRUCTION', item);
    },
    toggleTab(idx) {
      this.activeTab = idx;
    }
  },
  created() {
    this.getConstruction();
    this.getInox();
    this.getView();
    this.getDirection();
    this.getSafe();
    this.orderWidth = this.dimensions.width;
    this.orderLength = this.dimensions.height;
  }
};
</script>

<style lang="scss">
.accordion {
  margin: 20px 30px 0;

  &__empty {
    color: #ffffff;
  }

  &__item {
    border: none;
    margin-top: 10px;

    &--active {
      border: 1px solid #c70552;
    }
  }

  &__text {
    padding-left: 16px;
  }

  &__heading {
    width: 100%;
    height: 40px;
    background: #272c30;
    border-radius: 3px;
    text-align: left;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: relative;
    &--active {
      background: #c70552;
    }
    &:before {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 10px;
      height: 16px;
      background: url(~@/assets/images/angle-up.svg) no-repeat 0 0/10px 6px,
        url(~@/assets/images/angle-down.svg) no-repeat 0 100%/10px 6px;
      .accordion__item--opened & {
        background: url(~@/assets/images/angle-up.svg) no-repeat 0 100%/10px 6px,
          url(~@/assets/images/angle-down.svg) no-repeat 0 0/10px 6px;
      }
    }
  }

  &__content {
    margin: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s linear;
    &--active {
      max-height: 1450px;
    }
  }
}

.page-width {
  padding: 30px;
}

.options {
  margin: 10px -10px 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 10px;
    opacity: 0.5;
    &--active {
      opacity: 1;
      cursor: pointer;
    }
    &--current {
      cursor: default;
    }
    &--active:hover,
    &--current {
      .options__bg {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 8px solid #c70552;
          z-index: 2;
        }
      }
    }
  }

  &__bg {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &__inp {
    visibility: hidden;
  }

  &__title {
    text-align: center;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    margin-top: 10px;
  }
}
.radio {
  margin: 0px 20px;
  .option {
    &__0 {
      margin-top: 0;
    }
  }
}
.categories {
  flex: 1 1 100%;
  flex-direction: column;
  @include maw($xl) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__main {
    flex: 0 0 50%;
    @include maw($xl) {
      display: flex;
      flex-direction: column;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    @include maw($xl) {
      max-width: 100%;
    }
    @include maw($md) {
      max-width: 100%;
    }
  }
  &__present {
    flex: 1 0 100%;
    @include maw($xl) {
      flex: 1 0 50%;
      max-width: 50%;
    }
  }
  @include maw($md) {
    display: block;
  }

  &__hr {
    margin: 20px 0;
    border: 1px solid #aaa;
  }
  .hr__last {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.option {
  margin-left: 30px;
  display: flex;
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    white-space: nowrap;
    position: relative;
    padding: 4px 20px 0 40px;
    cursor: pointer;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    display: flex;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #7d8b97;
    border-radius: 100px;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #c70552;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
</style>

<style lang="scss">
.size {
  font: 15px/18px 'ProximaNovaBold', sans-serif;
  color: #ffffff;
  margin-top: 26px;

  &__title {
    font: 15px/18px 'ProximaNovaBold', sans-serif;
  }
  &__text {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    justify-content: space-between;
    > span {
      position: relative;
    }
    label.error {
      position: absolute;
      left: 5px;
      top: 100%;
      margin-top: 5px;
    }
  }
  &__group {
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
  &__lab {
    padding-right: 5px;
  }
  &__field {
    margin: 0 5px;
    padding-left: 15px;
    overflow: hidden;
    width: 81px;
    height: 40px;
    background: #272c30;
    border: 1px solid #7d8b97;
    box-sizing: border-box;
    border-radius: 3px;
    color: #ffffff;
    font: 15px/18px 'proxima_nova_regular', sans-serif;
    @include miw($md) {
      width: 101px;
    }
    &--full {
      flex: 1;
      width: inherit;
    }
  }
}
.additionaly {
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #c2cfd3;
  margin-top: 20px;
}
</style>
